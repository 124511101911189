import React from "react";
import {
    Button,
    FormControl,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { debounce, Field, useHttp } from "@builtbypixel/nucleus";

const Editor = ({ annotation, onChange, onSubmit, onDelete }) => {
    const Http = useHttp();

    const handleSearchProducts = debounce((search, callback) => {
        return Http.get(`/products?pagination=10&search=${search}`).then(res => callback(res.data.data));
    }, 1000);

    const onProductChange = (value) => {
        onChange({
            ...annotation,
            data: {
                ...annotation.data,
                product: {
                    id: value.id,
                    name: value.name,
                },
                text: value.name,
            }
        });
    };

    const onCancel = () => {
        onChange({});
    };

    return (
        <>
            <Modal
                isOpen={true}
                onClose={onCancel}
            >
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Link to</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody pb={6}>
                        <FormControl>
                            <Field
                                name={"product"}
                                value={annotation.data?.product}
                                component={"select"}
                                loadOptions={handleSearchProducts}
                                rules={{ required: true }}
                                label={"Product or Product Code"}
                                labelKey={"name"}
                                valueKey={"id"}
                                isAsync={true}
                                onChange={onProductChange}
                                isClearable={false}
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='green' onClick={onSubmit} isDisabled={!annotation.data?.product}>
                            Save
                        </Button>
                        <Button colorScheme='red' ml="auto" onClick={onDelete}>Delete</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Editor;
