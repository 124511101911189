import React from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Button } from "@chakra-ui/react";
import setup from "./setup";

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: "Branch",
      accessor: "branch",
      id: "edit",
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            {row.original.branch.name}
          </Button>
        </LinkButton>
      ),
    }
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
