import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: '',
        fields: [
          {
            name: 'name',
            component: 'text',
            placeholder: 'category name',
            label: 'Category Name',
          },
          {
            name: 'slug',
            component: 'text',
            label: 'Slug',
            isDisabled: true,
          },
          {
            name: 'url',
            component: 'text',
            placeholder: 'url',
            label: 'url',
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
