import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";

import setup from "./setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "category name",
            label: "Category Name",
          },
          {
            name: "app_featured",
            component: "switch",
            label: "App Featured",
          }
        ],
      }
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
