import React, { useEffect, useState } from 'react';
import { EditView, Field } from "@builtbypixel/nucleus";
import { useWatch } from 'react-hook-form';

import setup from './setup';
import { Container, Flex, Text, Grid, GridItem } from "@chakra-ui/react";
import { useHttp } from "../../Nucleus";
import AppEventTimePicker from "../../components/AppEventTimePicker/AppEventTimePicker";

const BrandOrCategory = ({ categories, brands }) => {
  const section = useWatch({ name: 'section' });

  return (
      <Flex direction='row' width="full" gap="8">
        {section?.id === 2 && (
        <Field name={'category'} label="Category" component="select" labelKey="name" valueKey="id" options={categories} help="Please only select a category if you have selected a Category Banner as the section." />
            )}
        {section?.id === 4 && (
        <Field name={'brand'} label="Brand" component="select" labelKey="name" valueKey="id" options={brands} help="Please only select a brand if you have selected a Brand Banner as the section." />
            )}
      </Flex>
  )
}

const EditForm = () => {
  const Http = useHttp();
  const [branches, setBranches] = useState([]);
  const [bannerSections, setBannerSections] = useState([]);
  const [sites, setSites] = useState([]);
  const [groups, setGroups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const fetchBranches = async () => {
      const response = await Http.get('/branches');

      setBranches(response.data.data);
    };
    fetchBranches();
  }, [Http]);

  useEffect(() => {
    const fetchBannerSections = async () => {
      const response = await Http.get('/banner-sections');

      setBannerSections(response.data.data);
    };
    fetchBannerSections();
  }, [Http]);

  useEffect(() => {
    const fetchSites = async () => {
      const response = await Http.get('/sites');

      setSites(response.data.data);
    };
    fetchSites();
  }, [Http]);

  useEffect(() => {
    const fetchGroups = async () => {
      const response = await Http.get('/all-customer-groups');

      setGroups(response.data.data);
    };
    fetchGroups();
  }, [Http]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await Http.get('/categories/top-level');

      setCategories(response.data);
    };
    fetchCategories();
  }, [Http]);

  useEffect(() => {
    const fetchBrands = async () => {
      const response = await Http.get('/all-brands');

      setBrands(response.data.data);
    };
    fetchBrands();
  }, [Http]);

  const formattedGroupLabel = (option) => {
    return `${option.scheme} - ${option.branch}`;
  }

  return (
    <EditView setup={setup} isFullWidth={true}>
      <Container
        p={{ base: "10px", sm: "15px" }}
        w='100%'
        variant='card'
        rounded='none'
        maxWidth='none'
      >
        <Flex direction='column' width="full" gap="8">
          <Field name={'name'} label={'Name'} rules={{ required: true }} component="text" />
          <Flex direction='row' width="full" gap="8">
            <Field name={'section'} label="Section" component="select" labelKey="name" valueKey="id" options={bannerSections} width="50%" required={{ required: true }} />
            <Field name={'sites'} label="Site" component="select" labelKey="name" isMulti valueKey="id" options={sites} width="50%" help="Please leave this blank to select all sites" />
          </Flex>
          <BrandOrCategory categories={categories} brands={brands} />
          <Flex direction='row' width="full" gap="8">
            <Field name={"branches"} label={"Branches"} component={"select"} isMulti labelKey="name" valueKey="id" options={branches} width="50%" help="Please leave this blank to select all branches"/>
            <Field name={"groups"} label={"Groups"} component={"select"} isMulti labelKey="scheme" formatOptionLabel={formattedGroupLabel} valueKey="id" options={groups} width="50%" help="Please leave this blank to select all price schemes" />
          </Flex>
          <Grid templateColumns='repeat(2, 1fr)' gap={4}>
            <GridItem>
              <Text fontSize={11} fontWeight={600} color='gray.600' mb='3px'>Start Date</Text>
              <AppEventTimePicker
                placeholder='Start Date'
                help='Start Date'
                name='start_date'
                showTimeSelect
                dateFormat='dd/MM/yyyy HH:mm'
              />
            </GridItem>
            <GridItem>
              <Text fontSize={11} fontWeight={600} color='gray.600' mb='3px'>End Date</Text>
              <AppEventTimePicker
                placeholder='End Date'
                help='End Date'
                name='end_date'
                showTimeSelect
                dateFormat='dd/MM/yyyy HH:mm'
              />
            </GridItem>
          </Grid>
          <Flex direction='row' width="full" gap="8">
            <Field name={"active"} label={"Active"} component={"switch"} width="50%" />
          </Flex>
          <Field name={"web_banners"} label={"Web Banners"} component="repeater" columns={2} layout="vertical" fieldArray={[{
            name: "image_a",
            label: "Image A",
            component: "file",
            required: true,
            width: '33%',
            max: 1
          }, {
            name: "image_b",
            label: "Image B",
            component: "file",
            width: '33%',
            max: 1
          }, {
            name: "link",
            label: "Link",
            component: "text",
            width: '33%'
          }, {
            name: "logged_out",
            label: "Display Logged Out",
            component: "switch"
          }]} />
          <Field name={"mobile_banners"} label={"Mobile Banners"} component="repeater" columns={2} layout="vertical" fieldArray={[{
            name: "image_a",
            label: "Image A",
            component: "file",
            required: true,
            width: '33%',
            max: 1
          }, {
            name: "image_b",
            label: "Image B",
            component: "file",
            width: '33%',
            required: true,
            max: 1
          }, {
            name: "link",
            label: "Link",
            component: "text",
            width: '33%'
          }, {
            name: "logged_out",
            label: "Display Logged Out",
            component: "switch"
          }]} />
        </Flex>
      </Container>
    </EditView>
  );
};

export default EditForm;
