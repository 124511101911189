import Login from "../containers/Auth/Login";
import ForgotPassword from "../containers/Auth/ForgotPassword";
import ResetPassword from "../containers/Auth/ResetPassword";
import ProductImage from "../../containers/Custom/productImage";
import HomePageBrands from "../../containers/Custom/homeTopBrands";

import NotFound from "../containers/404";

const routes = [
  {
    path: "/",
    title: "Login",
    exact: true,
    auth: false,
    component: Login,
  },
  {
    path: "/forgot-password",
    title: "Forgot Password",
    exact: true,
    auth: false,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    title: "Reset Password",
    exact: true,
    auth: false,
    component: ResetPassword,
  },
  {
    path: "/homepage-brands",
    title: "Homepage Brands",
    component: HomePageBrands
  },
  {
    path: "/product-image",
    title: "Product Image",
    component: ProductImage,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default routes;
