import React, { useState } from "react";
import Editor from "./Editor";
import _ from "underscore";
import ImageAnnotation from "react-image-annotation/lib/components/Annotation";
import { useFormContext, useWatch } from "react-hook-form";

const Annotation = () => {
    const annotations = useWatch({ name: "hotspots", defaultValue: [] });
    const { setValue } = useFormContext();
    const [annotation, setAnnotation] = useState({});
    const image = useWatch({ name: "image" });

    const setAnnotations = annotations => {
        setValue("hotspots", annotations);
    };

    const onChange = (annotation) => {
        const x = annotation.selection?.anchorX;
        const y = annotation.selection?.anchorY;

        let closeAnnotations = _.filter(annotations, function(stateAnnotation) {
            let distanceFromX = x >= stateAnnotation.geometry.x && x <= stateAnnotation.geometry.x + stateAnnotation.geometry.width;
            let distanceFromY = y >= stateAnnotation.geometry.y && y <= stateAnnotation.geometry.y + stateAnnotation.geometry.height;

            return distanceFromX && distanceFromY;
        });

        if (closeAnnotations.length > 0) {
            activateAnnotation(closeAnnotations[0]);
        } else {
            if (!annotation.geometry || (annotation.geometry.height > 0 && annotation.geometry.width > 0)) {
                setAnnotation(annotation);
            }
        }
    }

    const activateAnnotation = (annotation) => {
        setAnnotation({
            ...annotation,
            selection: {
                showEditor: true,
                mode: 'EDITING'
            },
        });
    }

    const onSubmit = (annotation) => {
        const { geometry, data } = annotation;

        setAnnotation({});

        const target = annotations.find(obj => isSameAnnotation(obj, annotation));

        if (target) {
            Object.assign(target, annotation);
            setAnnotations(annotations);
        } else {
            setAnnotations(annotations.concat({
                geometry,
                data: {
                    ...data,
                }
            }));
        }
    };

    const onDelete = () => {
        setAnnotations(annotations.filter(obj => !isSameAnnotation(obj, annotation)));
        setAnnotation({});
    };

    const isSameAnnotation = (oldAnnotation, newAnnotation) => {
        return oldAnnotation.geometry.x === newAnnotation.geometry.x
            && oldAnnotation.geometry.y === newAnnotation.geometry.y;
    }

    return (
        <>
            {image && image[0] && <ImageAnnotation
                src={image[0].url}
                annotations={annotations}
                value={annotation}
                onChange={onChange}
                onSubmit={onSubmit}
                renderEditor={({ annotation, onChange, onSubmit }) => (
                    <Editor
                        annotation={annotation}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        onDelete={onDelete}
                    />
                )}
            />}
        </>
    );
}

export default Annotation;
