import React, { useState, useEffect } from "react";
import { EditView, Field, useHttp, debounce } from "@builtbypixel/nucleus";
import setup from "./setup";
import WrapperView from "../../components/CustomEditWrapper";
import Annotation from "./components/Annotation";
import { Grid, Box, Text } from "@chakra-ui/react";

const EditForm = () => {

  const [branches, setBranches] = useState([]);
  const [categories, setCategories] = useState([]);
  const Http = useHttp();

  useEffect(() => {
    Http.get(`/branches`).then((res) => {
      setBranches(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    Http.get("/categories/top-level").then((res) => {
      setCategories(res.data);
    });
  }, []);


  const handleSearchGroups = debounce((search, callback) => {
    return Http.get(`/all-groups?pagination=9999&search=${search}`).then(
      (res) => {
        callback(res.data.data);
      }
    );
  }, 1000);


  return (
    <EditView setup={setup} isFullWidth={false}>
      <WrapperView>
        <Field
          name='name'
          component='text'
          label='Name'
          rules={{ required: true }}
        />
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <Field
            name='branches'
            component='select'
            label='Branches'
            isMulti={true}
            labelKey='name'
            valueKey='id'
            help="Please leave blank to select all branches."
            options={branches}
          />
          <Field
            name='price_schemes'
            component='select'
            label='Price Schemes'
            loadOptions={handleSearchGroups}
            isAsync={true}
            isMulti={true}
            formatOptionLabel={(group) => (
              <Box>
                <Text>{group.scheme} ({group?.branch?.name})</Text>
              </Box>
            )}
            labelKey='scheme'
            valueKey='id'
            help="Please leave blank to select all price schemes."
          />
        </Grid>
        <Field
          name='category'
          component='select'
          label='Category'
          labelKey='name'
          valueKey='id'
          rules={{ required: true }}
          options={categories}
        />
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          <Field
            name='width'
            component='number'
            label='Width'
            rules={{ required: true }}
          />
          <Field
            name='unit'
            component='nativeSelect'
            label='Unit'
            defaultValue="Meters"
            options={["Meters", "Feet"]}
            rules={{ required: true }}
          />
          <Field
            name='shelves'
            component='number'
            label='Shelves'
            rules={{ required: true }}
          />
        </Grid>

        <Field
          name='image'
          component='file'
          label='Image'
          rules={{ required: true }}
        />

        <Annotation />
      </WrapperView>
    </EditView>
  );
};

export default EditForm;
