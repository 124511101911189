import React, { useMemo } from "react";
import { AutoFields, EditView } from "@builtbypixel/nucleus";
import { Text, Box } from "@chakra-ui/react";

import setup from "../setup";

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: "Main Details",
        tab: "Details",
        fields: [
          {
            name: "name",
            component: "text",
            placeholder: "Name",
            label: "Page Name",
          },
          {
            name: "url",
            component: "text",
            placeholder: "e.g. /products",
            label: "Page URL",
          },
        ],
      },
      // {
      //   group: "Carousels",
      //   help: "Each carousel will be added to both Parfetts and Gold sites",
      //   tab: "Carousels",
      //   fields: [
      //     {
      //       name: 'content.carousels',
      //       label: '',
      //       component: 'repeater',
      //       layout: 'vertical',
      //       fieldArray: [
      //         {
      //           name: 'image',
      //           component: 'file',
      //           label: 'Image',
      //           help: "Uploaded images must be 3000px X 1000px resolution or bigger keeping the same 3:1 aspect ratio",
      //           max: 1
      //         },
      //         {
      //           name: "link",
      //           component: "text",
      //           label: "Link",
      //         },
      //         {
      //           name: "start_date",
      //           component: "date",
      //           help: "Leave blank to always show the banner",
      //           label: "Start Date",
      //         },
      //         {
      //           name: "end_date",
      //           component: "date",
      //           help: "Leave blank to always show the banner",
      //           label: "End Date",
      //         },
      //         {
      //           name: 'logged_out',
      //           component: 'switch',
      //           label: 'Show when logged out'
      //         }
      //       ]
      //     }
      //   ]
      // },
      {
        group: "SEO",
        tab: "SEO",
        fields: [
          {
            name: "content.seo_title",
            component: "text",
            label: "SEO title"
          },
          {
            name: "content.seo_description",
            component: "textarea",
            label: "SEO description"
          }
        ]
      }
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
