import React from "react";
import { LinkButton, ListWrapper } from "@builtbypixel/nucleus";
import { Button, Text } from "@chakra-ui/react";
import setup from "./setup";

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      id: "edit",
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
          <Button variant="link" variantColor="secondary" rounded="md">
            {row.original.name}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: "Active",
      accessor: "active",
      Cell: ({ value }) => (value === true ? <Text color="green">Yes</Text> :<Text>No</Text>),
    }
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
