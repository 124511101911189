import axios from 'axios'
import { useHttp } from './useHttp'

/* eslint-disable */

const Http = useHttp()

const apiPath = process.env.REACT_APP_API
const prefix = process.env.REACT_APP_API_PREFIX
const loginPath = process.env.REACT_APP_LOGIN_PATH;

export const useGet = (url, params) => {
   const isCurrent = url.includes("current")
   // console.log(isCurrent, "<-- isCurrent")
   if(isCurrent) return axios.get(`${apiPath}/${prefix}/admin-users/current`)
   return Http.get(url, { params: params }).then((res) => res.data)
}
